import I18n from "i18n-js"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    cents: Number,
    options: Object,
  }

  connect () {
    super.connect()

    this.update()
  }

  update () {
    this.element.textContent = I18n.toCurrency(this.centsValue / 100.0, this.optionsValue)
  }

  centsValueChanged () {
    this.update()
  }
}
