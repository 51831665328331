import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = { identifier: String }

  connect () {
    this.resizeObserver = new ResizeObserver(this.onResize)
    this.resizeObserver.observe(this.element)
  }

  disconnect () {
    this.resizeObserver.disconnect()
    this.onResize()
  }

  onResize = () => {
    this.rootElement.style.setProperty(`--${this.identifierName}-height`, `${this.element.offsetHeight}px`)
    this.rootElement.style.setProperty(`--${this.identifierName}-width`, `${this.element.offsetWidth}px`)
  }

  get rootElement () {
    return document.documentElement
  }

  get identifierName () {
    const identifier = this.identifierValue || this.element.id

    if (!identifier) {
      throw("Please provide an identifier value or an id")
    }

    return identifier
  }
}
