import ApplicationBridgeComponent from "./application_bridge_component"

export default class extends ApplicationBridgeComponent {
  static component = "analytics"

  connect() {
    super.connect()

    // Assign the controller to the element:
    this.element[this.identifier] = this
  }

  track(event, properties) {
    this.send("track", {
      event,
      properties
    });
  }
}
