import ApplicationController from "../application_controller"

/* This class is meant to be extended for each Payment Method that we want to support */

export const NAMESPACE = "payment-method" // Used to communicate to the `PaymentIntentsController`

export default class extends ApplicationController {
  async connect () {
    super.connect()

    this.dispatch("connected", { prefix: NAMESPACE, detail: { controller: this } })
  }

  disconnect () {
    super.disconnect()

    this.dispatch("disconnected", { prefix: NAMESPACE, detail: { controller: this } })
  }

  disable () {
    this.element.classList.add("disabled")
  }

  enable () {
    this.element.classList.remove("disabled")
  }

  success (detail) {
    this.dispatch("success", { prefix: NAMESPACE, detail })
  }

  ready () {
    this.element.classList.remove("initializing")
    this.element.classList.add("ready")

    this.dispatch("ready")
  }

  initializing () {
    this.element.classList.add("initializing")

    this.dispatch("initializing")
  }

  async getAdyenCheckout () {
    // Short-hand delegation to the paymentIntentsController.
    //
    // Allows Payment Method to receive a configured Adyen Checkout instance that is shared
    // among all the PaymentMethod controllers.
    return this.paymentIntentController.getAdyenCheckout()
  }

  async getChargebeeInstance () {
    // Short-hand delegation to the paymentIntentsController
    //
    // Allows Payment Method to receive a configured Chargebee instance that is shared
    // among all the PaymentMethod controllers
    return this.paymentIntentController.getChargebeeInstance()
  }

  get paymentIntentController () {
    // Finds the nearest PaymentIntentController in parent elements
    return this.element.closest("[data-controller~=chargebee--payment-intent]")["chargebee--payment-intent"]
  }
}
