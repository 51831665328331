import Request from "../../lib/http/request"
import AbstractPaymentMethodController from "./abstract_payment_method_controller"

const SCRIPT_URL = "https://www.paypalobjects.com/api/checkout.js"

export default class extends AbstractPaymentMethodController {
  static targets = []

  static values = {
    clientId: String,
    expressCheckoutUrl: String,
    isSandbox: Boolean,
  }

  static errorMessage;

  async connect () {
    super.connect()

    this.initializing()

    await import(SCRIPT_URL)

    this.setupPayPalButton()

    this.ready()
  }

  // Legacy style PayPal checkout.js integration
  // Documentation here: https://github.com/paypal/paypal-checkout-components/blob/master/docs/implement-checkout.md
  setupPayPalButton () {
    const env = this.isSandboxValue ? "sandbox" : "production"
    const clientOptions = { [env]: this.clientIdValue }

    this.paypal.Button.render({
      env: env,
      client: clientOptions,
      style: {
        size: "responsive",
        tagline: false,
        shape: "pill",
        label: "pay",
        height: 49 // Matches our buttons
      },
      payment: this.getPayment,
      onAuthorize: this.onAuthorize,
      onCancel: this.onError,
    }, this.element)
  }

  onAuthorize = async (data) => {
    const { paymentToken } = data

    if (paymentToken) {
      this.success({ paypal: { token: paymentToken } })
    }
  }

  getPayment = async () => {
    const request = new Request("POST", this.expressCheckoutUrlValue, { responseKind: "json" })
    const response = await request.perform()
    const json = await response.json()
    const { token } = json

    return token
  }

  onError = () => {
    // Handle this?
  }

  get paypal () {
    return window.paypal
  }
}

