import { BridgeElement } from "@hotwired/strada"

import ApplicationBridgeComponent from "./application_bridge_component"

export default class extends ApplicationBridgeComponent {
  static component = "nav-bar"
  static targets = [ "rightAction", "leftAction" ]
  static values = { backNavigationDisabled: Boolean }

  connect() {
    super.connect()

    this.notifyBridgeOfLeftActions()
    this.notifyBridgeOfRightActions()
  }

  backNavigationDisabledValueChanged () {
    this.send("setBackNavigationDisabled", { value: this.backNavigationDisabledValue })
  }

  // Private

  notifyBridgeOfLeftActions() {
    this.sendActions("setHeaderLeftActions", this.leftActions)
  }

  notifyBridgeOfRightActions() {
    this.sendActions("setHeaderRightActions", this.rightActions)
  }

  sendActions = (method, actions) => {
    this.send(method, {
      actions: actions.map(action => ({
        key: action.key,
        title: action.title,
        index: action.index,
        iconUrl: action.iconUrl,
        badgeCount: action.badgeCount,
        type: action.type
      }))
    }, ({ data: { selectedIndex }}) => {
      const action = actions[selectedIndex]
      action?.click()
    })
  }

  makeAction(element, index) {
    const bridgeElement = new BridgeElement(element)

    return {
      index,
      title: bridgeElement.title,
      key: bridgeElement.bridgeAttribute("key"),
      iconUrl: bridgeElement.bridgeAttribute('icon-url'),
      badgeCount: bridgeElement.bridgeAttribute('badge-count'),
      type: bridgeElement.bridgeAttribute('type'),
      click: () => bridgeElement.click()
    }
  }

  get leftActions () {
    return this.leftActionTargets.map(this.makeAction)
  }

  get rightActions () {
    return this.rightActionTargets.map(this.makeAction)
  }
}
