import { debounce } from "../helpers"
import ApplicationController from "./application_controller"

function isEmpty (input) {
  if (input.type === "radio" || input.type === "checkbox") {
    return input.form.querySelector(`input[name="${input.name}"]:checked`) === null
  }

  return input.value.toString().trim().length == 0
}


async function polyfillFormData () {
  if (typeof FormData.prototype.entries !== "function") {
    await import("formdata-polyfill")
  }
}

export default class extends ApplicationController {
  initialComparableFormData

  async connect () {
    super.connect()

    await polyfillFormData()
    this.initialComparableFormData = this.comparableFormData

    const updated = debounce(this.updated.bind(this), 50)
    this.inputs.forEach((target) => {
      this.attachEventListener(target, "keyup change", updated)
    })

    this.updated()
  }

  updated () {
    this.toggleClasses()

    if (this.isValid) {
      const event = new Event("form-state:valid")
      this.element.dispatchEvent(event)
    }
  }

  toggleClasses () {
    const isPristine = this.isPristine
    const isValid = this.isValid

    this.formElement.classList.toggle("pristine", isPristine)
    this.formElement.classList.toggle("dirty", !isPristine)
    this.formElement.classList.toggle("valid", isValid)
    this.formElement.classList.toggle("error", !isValid)
  }

  get isPristine () {
    return this.comparableFormData === this.initialComparableFormData
  }

  get isValid () {
    return (
      this.invalidInputs.length === 0 &&
        this.specialInvalidInputs.length === 0
    )
  }

  get inputs () {
    return this.element.querySelectorAll("input, textarea, select")
  }

  get invalidInputs () {
    // TODO: Decide if this should use #inputTarget instead of iterating inputs.
    return this.element.querySelectorAll(
      "input:invalid, select:invalid, textarea:invalid, [aria-invalid=true]"
    )
  }

  get specialInvalidInputs () {
    // TODO: Decide if this should use #inputTarget instead of iterating inputs.
    return [
      ...this.element.querySelectorAll("input[type=hidden][required]"),
    ].filter(isEmpty)
  }

  get comparableFormData () {
    return JSON.stringify([...new FormData(this.element).entries()])
  }

  get formElement () {
    return this.element
  }
}
