export function nextFrame () {
  return new Promise(requestAnimationFrame)
}

export function nextIdle () {
  return new Promise(window.requestIdleCallback || setTimeout)
}

export function delay (ms = 1) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function transitionComplete (element) {
  return new Promise((resolve) => {
    const duration =
      Number(
        getComputedStyle(element)
          .transitionDuration.split(",")[0]
          .replace("s", "")
      ) * 1000;

    setTimeout(() => {
      resolve();
    }, duration);
  });
}
