import { Idiomorph } from "idiomorph/dist/idiomorph.esm.js"

export const morph = function () {
  this.targetElements.forEach(element => {
    Idiomorph.morph(
      element,
      this.templateElement.innerHTML
    )
  })
}
