import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect () {
    this.requestSubmit()
  }

  requestSubmit = () => {
    this.warnIfSubmittingWithoutTurboFrame()
    this.formElement.requestSubmit()
  }

  warnIfSubmittingWithoutTurboFrame = () => {
    if (this.formElement.closest("turbo-frame") == null) {
      console.warn(
        "Using `autosubmit` on a form without a turbo-frame might result in unexpected behavior on restoration visits. Is this really what you want?"
      )
    }
  }

  get formElement () {
    return this.element.closest("form")
  }
}
