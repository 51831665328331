import Request from "../../lib/http/request"
import { push_tokens_path } from "../../helpers/routes"

const availablePushables = []

export function asPushable (pushableType, Controller) {
  return class extends Controller {
    async connect () {
      super.connect()

      const available = await this.isAvailable()
      if (available) {
        availablePushables.push(this)
      }
    }

    disconnect () {
      if (availablePushables.includes(this)) {
        availablePushables.splice(availablePushables.indexOf(this), 1)
      }

      super.disconnect()
    }

    async persistPushable (pushableAttributes) {
      const body = JSON.stringify({
        pushable_type: pushableType,
        pushable_attributes: pushableAttributes
      })

      const url = push_tokens_path()
      const request = new Request("POST", url, { body, contentType: "application/json" })
      const response = await request.perform()

      if (response.ok) {
        const event = new CustomEvent("pushable:persisted", { bubbles: true, detail: { pushableType, pushableAttributes } })
        this.element.dispatchEvent(event)

        return true
      } else {
        throw new Error("Failed to persist pushable.", { response })
      }
    }
  }
}

export function withPushable(Controller) {
  return class extends Controller {
    _pushable = undefined;

    get pushable () {
      return this._pushable ||= availablePushables[0]
    }
  }
}
