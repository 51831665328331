export default (Controller) => {
  return class extends Controller {
    attachedEventListeners = []

    disconnect () {
      super.disconnect()

      this.attachedEventListeners.forEach(({ element, type, listener }) => {
        element.removeEventListener(type, listener)
      })
    }

    attachEventListener (element, types, listener, options) {
      types.split(" ").forEach(type => {
        element.addEventListener(type, listener, options)
        this.attachedEventListeners.push({ element, type, listener })
      })
    }
  }
}
