import { BridgeComponent, BridgeElement } from "@hotwired/strada"

import withConnectedDataAttributes from "../mixins/withConnectedDataAttributes"

class ApplicationBridgeComponent extends BridgeComponent {
  sendAsync = async (event, data = {}) => {
    return new Promise((resolve) => {
      this.send(event, data, ({ data }) => {
        resolve(data)
      })
    })
  }
}

export default withConnectedDataAttributes(ApplicationBridgeComponent)
