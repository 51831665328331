import ApplicationController from "./application_controller"
import { pushEvent as dataLayerPushEvent } from "../lib/data_layer_events"
import { current } from "../helpers"

export default class extends ApplicationController {
  trackEvent = ({ params: { payload }})  => {
    if (!payload) { return }

    dataLayerPushEvent(payload)
  }

  trackPageView = () => {
    dataLayerPushEvent({ location: location.href.split('#')[0] })
    dataLayerPushEvent({ event: 'page_view' });
  }

  identify() {
    dataLayerPushEvent({
      user_id: this.userId,
      sift_id: current.user.sift_id,
      impersonating: current.user.impersonating,
      traits: this.traits
    })
  }

  get userId () {
    return current.user.id
  }

  get traits () {
    try {
      return JSON.parse(current.user.identifyTraits)
    } catch (e) {
      return null
    }
  }
}
