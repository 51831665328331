export default (Controller) => {
  return class extends Controller {
    connect() {
      super.connect()

      // Toggle a data attribute on the element to indicate that the controller is connected.
      this.element.setAttribute(this.connectedDataAttributeName, true)
    }

    disconnect () {
      super.disconnect()

      this.element.setAttribute(this.connectedDataAttributeName, false)
    }

    // Private
    get connectedDataAttributeName () {
      return `data-connected-with-${this.identifier}`
    }
  }
}
