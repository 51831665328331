import ApplicationBridgeComponent from "./application_bridge_component"
import Request from "../../lib/http/request"

export default class extends ApplicationBridgeComponent {
  static component = "iaphub"

  static values = {
    sessionUrl: { type: String, default: "/billing/iaphub/session" },
  }

  purchase({ params: { sku } }) {
    this.#dispatchEvent("before-purchase", { detail: { sku } })

    this.send("buy", { sku }, async ({ data }) => {
      const { transaction, error } = data

      if (error) {
        this.#dispatchEvent("purchase-error", { detail: { error } })

        if (error.message) {
          alert(error.message)
        }

        return false
      } else if(transaction) {
        this.#dispatchEvent("after-purchase", { detail: { transaction: transaction } })

        return transaction
      }
    })
  }

  updateSession() {
    this.send("getProductsForSale", {}, async ({ data }) => {
      const { products, error } = data

      if (error) {
        console.error(error)

        return
      } else if (products) {
        await this.#request(this.sessionUrlValue, "PATCH", { session: { products_for_sale: products } })

        this.#dispatchEvent("session-updated", { detail: { products } })

        return products
      }
    })
  }

  restorePurchases() {
    this.send("restore", {}, async ({ data: { response } }) => {
      this.#dispatchEvent("restored-purchases", { detail: response })

      return response
    })
  }

  // Private

  async #request(url, method = "POST", data = {}) {
    const body = JSON.stringify(data)
    const request = new Request(method, url, { body, contentType: "application/json" })

    return await request.perform()
  }

  #dispatchEvent(name, options = {}) {
    const event = new CustomEvent(`${this.identifier}:${name}`, { bubbles: true, ...options })

    return this.element.dispatchEvent(event)
  }
}
