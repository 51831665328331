import ApplicationController from "./application_controller"
import withEventAttachable from "./mixins/withEventAttachable"

class FormController extends ApplicationController {
  static targets = ["input", "form"]

  connect() {
    super.connect()

    this.attachEventListener(this.formElement, "submit", this.didSubmit)
  }

  didSubmit = () => {
    this.formElement.ariaBusy = true
  }

  reset () {
    this.formElement.reset()
  }

  focus () {
    this.inputTargets[0]?.focus()
  }

  requestSubmit () {
    this.formElement.requestSubmit()
  }

  get formElement () {
    if (this.hasFormTarget) {
      return this.formTarget
    }

    return this.element.closest("form")
  }

  get submitElements () {
    return [
      ...this.element.querySelectorAll("input[type=submit], button[type=submit]"),
      ...document.querySelectorAll(`button[type=submit][form="${this.formId}"]`)
    ]
  }

  get formId () {
    return this.formElement.id
  }

  disable = () => {
    this.submitElements.forEach((element) => {
      element.disabled = true
    })
  }

  enable = () => {
    this.submitElements.forEach((element) => {
      element.disabled = null
    })
  }
}

export default withEventAttachable(FormController)
