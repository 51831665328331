// A Snapshot class that will extract morphable elements tagged with [data-turbo-morph] and [id]
// and allow generating a map of elements that are morphable between snapshots.

class MorphableSnapshot {
  constructor(element) {
    this.element = element
  }

  get morphableElements() {
    return [...this.element.querySelectorAll("[id][data-turbo-morph]")]
  }

  getMorphableElementById(id) {
    return this.element.querySelector(`#${id}[data-turbo-morph]`)
  }

  getMorphableElementsMapForSnapshot(snapshot) {
    const morphableElementsMap = {}

    for (const morphableCurrentElement of this.morphableElements) {
      const { id } = morphableCurrentElement
      const newMorphableElement = snapshot.getMorphableElementById(id)

      if (newMorphableElement) {
        morphableElementsMap[id] = {
          currentElement: morphableCurrentElement,
          newElement: newMorphableElement,
          newElementHTML: newMorphableElement.outerHTML,
        }
      }
    }

    return morphableElementsMap
  }
}

export default MorphableSnapshot
