const compact = (object) => {
  const result = {}
  for (const key in object) {
    const value = object[key]
    if (value !== undefined) {
      result[key] = value
    }
  }
  return result
}

export default class Request {
  constructor (method, url, options = {}) {
    this.method = method
    this.url = url
    this.options = options
  }

  perform () {
    return fetch(this.url, this.fetchOptions)
  }

  get fetchOptions () {
    return {
      method: this.method,
      headers: this.headers,
      body: this.body,
      signal: this.signal,
      credentials: "same-origin",
      redirect: "follow"
    }
  }

  get headers () {
    return compact({
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": document.head.querySelector("meta[name=csrf-token]")?.content,
      "Content-Type": this.contentType,
      "Accept": this.accept
    })
  }

  get contentType () {
    if (this.options.contentType) {
      return this.options.contentType
    } else if (this.body == null || this.body instanceof FormData) {
      return undefined
    } else if (this.body instanceof File) {
      return this.body.type
    } else {
      return "application/octet-stream"
    }
  }

  get accept () {
    switch (this.responseKind) {
    case "html":
      return "text/html, application/xhtml+xml"
    case "json":
      return "application/json"
    default:
      return "*/*"
    }
  }

  get responseKind () {
    return this.options.responseKind || "html"
  }

  get body () {
    return this.options.body
  }

  get signal () {
    return this.options.signal
  }
}
