import ApplicationController from "./application_controller"
import { nextFrame, scrollToElement } from "../helpers"

export default class extends ApplicationController {
  static values = { options: Object }

  async connect () {
    await nextFrame()

    scrollToElement(this.element, this.optionsValue)
  }
}
