import { Controller } from "@hotwired/stimulus"

import withEventAttachable from "./mixins/withEventAttachable"
import withConnectedDataAttributes from "./mixins/withConnectedDataAttributes"

class ApplicationController extends Controller {
  connect () {
    super.connect()

    // This attaches the controller to the element under it's identifier.
    // This way we can access the controller methods from outside Stimulus land,
    // eg. document.getElementById(..).modal.open() where 'modal' is the controller
    // identifier
    this.element[this.identifier] = this

  }
}

export default withEventAttachable(withConnectedDataAttributes(ApplicationController))
