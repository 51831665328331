import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = { count: Number }

  countValueChanged() {
    if (this.countValue) {
      this.element.dataset.turboPermanent = true
    } else {
      delete this.element.dataset.turboPermanent
    }
  }
}
