import { loadScript } from "../helpers"

const SDK_URL = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"

class Turnstile {
  async loaded () {
    if (window.turnstile) {
      return window.turnstile
    }

    return await this.load()
  }

  async load () {
    await loadScript(SDK_URL)

    Object.assign(this, window.turnstile)

    return window.turnstile
  }
}

export default new Turnstile()
