export async function loadScript (url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.src = url
    script.addEventListener("load", () => resolve(script), false)
    script.addEventListener("error", () => reject(script), false)
    document.body.appendChild(script)
  })
}

export async function loadScripts (urls) {
  return Promise.all(urls.map(loadScript))
}
