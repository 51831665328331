import Cookies from "js-cookie";
import ApplicationController from "./application_controller";
import { pushEvent } from "../lib/data_layer_events";

export default class extends ApplicationController {
  static values = {
    cookieName: String,
    events: { type: Array, default: [] },
  };

  connect() {
    super.connect();

    this.eventsValue.forEach((event) => {
      this.track(event);
    });
  }

  track(event) {
    this._trackEvent(event);
    this._claimEvent(event);
  }

  _trackEvent(event) {
    pushEvent(event);
  }

  _claimEvent({ uuid }) {
    let claimedEvents = this.claimedEvents;

    claimedEvents.push(uuid);
    Cookies.set(this.cookieNameValue, JSON.stringify(claimedEvents));

    this.eventsValue = this.eventsValue.filter((event) => event.uuid !== uuid);
  }

  get claimedEvents() {
    return JSON.parse(Cookies.get(this.cookieNameValue) || "[]");
  }
}
