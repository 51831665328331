import ApplicationBridgeComponent from "./application_bridge_component"
import { asPushable } from "../mixins/pushable"

class NotificationsController extends ApplicationBridgeComponent {
  static component = "notifications"

  async connect () {
    super.connect()

    await this.#persistIfSubscribed()
  }

  async subscribe (event) {
    return await this.#registerForPushNotifications()
  }

  async isAvailable () {
    return true
  }

  async isSubscribed () {
    const permissions = await this.#getPermissions()

    return !!permissions?.granted
  }

  async canBeAsked () {
    const permissions = await this.#getPermissions()

    return !!permissions?.canAskAgain
  }

  async debugPermissions () {
    const permissions = await this.#getPermissions()

    console.log(permissions)
    alert(JSON.stringify(permissions, null, 2))

    return permissions
  }

  async debugSubscribe () {
    const response = await this.sendAsync("registerForPushNotifications")

    console.log(response)
    alert(JSON.stringify(response, null, 2))

    await this.subscribe()
  }

  async #getPermissions () {
    const { permissions } = await this.sendAsync("getPermissions")

    return permissions
  }

  async #persistIfSubscribed () {
    const subscribed = await this.isSubscribed()

    if (subscribed) {
      this.#registerForPushNotifications()
    }
  }

  async #registerForPushNotifications () {
    const { devicePushToken, expoToken, appId } = await this.sendAsync("registerForPushNotifications")

    if (!devicePushToken || !expoToken) {
      return false
    }

    const result = await this.persistPushable({
      platform: devicePushToken.type,
      device_token: devicePushToken.data,
      expo_token: expoToken.data,
      app_id: appId
    })

    return result
  }
}

export default asPushable("ExpoPushToken", NotificationsController)
