import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect () {
    super.connect()

    disableBodyScroll(this.element)
    this.moveToDialogFrameIfNeeded()
  }

  disconnect () {
    super.disconnect()

    enableBodyScroll(this.element)
  }

  close () {
    this.element.remove()
  }

  closeAndPreventDefault (event) {
    this.close(event)

    event.preventDefault()
    return false
  }

  moveToDialogFrameIfNeeded = () => {
    if (this.dialogFrame && !this.dialogFrame.contains(this.element)) {
      this.dialogFrame.append(this.element)
    }
  }

  get dialogFrame () {
    return document.getElementById("dialog")
  }
}
