import Request from "../../lib/http/request"

import AbstractPaymentMethodController from "./abstract_payment_method_controller"

const camelize = (str) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export default class extends AbstractPaymentMethodController {
  static targets = ["card", "cardNumber", "expiry", "cvc", "payButton", "errorMessage", "shadowInput"]

  static values = {
    remotePaymentIntentUrl: String,
    publishableKey: String,
    siteId: String,
    genericErrorMessage: { type: String, default: "An error occurred. Try again with a different card." },
    refusedErrorMessage: { type: String, default: "Payment refused. Try again with a different card." },
    additionalData: Object,
  }

  static errorMessage;

  async connect () {
    super.connect()

    this.initializing()

    this.chargebeeInstance = await this.getChargebeeInstance()

    this.connectFields()
    this.togglePayButton(false)

    this.attachEventListener(this.element, "submit", this.submit)

    this.ready()
  }

  getInputStyleProps = () => {
    if (!this.hasShadowInputTarget) { return {} }

    const style = getComputedStyle(this.shadowInputTarget)
    const properties = ["font-size", "color", "letter-spacing", "font-variant", "font-style"]

    return properties.reduce((obj, property) => {
      return { ...obj, [camelize(property)]: style.getPropertyValue(property) }
    }, {})
  }

  async connectFields () {
    await this.chargebeeInstance.load("components")

    const baseStyle = {
      ...this.getInputStyleProps(),
      fontFamily: "Arial",
    }

    this.cardComponent = this.chargebeeInstance.createComponent("card", {
      placeholder: {
        number: this.hasCardNumberTarget ? this.cardNumberTarget.dataset.placeholder : null,
        cvv: this.hasCvcTarget ? this.cvcTarget.dataset.placeholder : null,
        expiry: this.hasExpiryTarget ? this.expiryTarget.dataset.placeholder : null,
      },
      style: {
        base: baseStyle,
        empty: {
          "::placeholder": {
            color: "red"
          }
        },
      },
      classes: {
        focus: "focus",
        invalid: "invalid",
        empty: "empty",
        complete: "complete",
      },
    })

    if (this.hasCardTarget) {
      this.cardComponent.at(this.cardTarget)
    } else {
      this.cardComponent.createField("number").at(this.cardNumberTarget)
      this.cardComponent.createField("expiry").at(this.expiryTarget)
      this.cardComponent.createField("cvv").at(this.cvcTarget)
    }

    this.cardComponent.on("change", this.onChange)
    this.cardComponent.on("focus", this.onFocus)

    this.cardComponent.mount()
  }

  onChange = (currentState) => {
    this.togglePayButton(currentState.complete)
  }

  onFocus = (...args) => {
    console.log(...args)
  }

  submit = async (event) => {
    event.preventDefault()

    const additionalData = this.additionalDataValue || {}

    this.submitting()

    try {
      const remotePaymentIntent = await this.getRemotePaymentIntent()
      const authorizedIntent = await this.cardComponent.authorizeWith3ds(remotePaymentIntent.data, additionalData)

      this.success({ card: { payment_intent_id: authorizedIntent.id } })
    } catch(error) {
      this.handleError(error)
      this.complete()
    }

    return false
  }

  async getRemotePaymentIntent () {
    const body = {}
    const request = new Request("POST", this.remotePaymentIntentUrlValue, { body, responseKind: "json" })
    const response = await request.perform()
    const json = await response.json()

    return json
  }

  handleError (error) {
    if (error.name.match(/PAYMENT_ATTEMPT_REFUSED/)) {
      this.errorMessage = this.refusedErrorMessageValue
    } else {
      // FIXME: Handle additional errors here, eg. XHR rejections etc.
      this.errorMessage = this.genericErrorMessageValue
    }
  }

  set errorMessage (value) {
    super.errorMessage = value

    this.errorMessageTarget.innerHTML = this.errorMessage

    const cardElements = this.hasCardTarget
      ? [this.cardTarget]
      : [this.cvcTarget, this.expiryTarget, this.cardNumberTarget]
  }

  get hasError () {
    return this.errorMessage && this.errorMessage.length > 0
  }

  togglePayButton (complete) {
    this.payButtonTarget.disabled = !complete
  }

  submitting () {
    //    this.errorMessage = null
    this.disable()
    this.dispatch("submitting")
    this.element.classList.add("submitting")
    this.element.ariaBusy = true
  }

  complete () {
    this.enable()

    this.dispatch("complete")
    this.element.classList.remove("submitting")
    this.element.ariaBusy = false
  }
}

