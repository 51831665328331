import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["input", "preview"]

  connect () {
    super.connect()

    this.element.classList.add("ready")
    this.setPreview()

    this.attachEventListener(this.element, "turbo:morph-element", this.setPreview)
  }

  setPreview = () => {
    this.previewTarget.innerText = this.selectedOption.dataset.preview || this.selectedOption.innerText
  }

  get selectedOption () {
    return this.inputTarget.options[this.inputTarget.selectedIndex]
  }
}
