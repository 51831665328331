import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  static targets = ["completionParamsInput", "form"]

  complete(event) {
    const { transaction } = event.detail

    this.completionParamsInputTarget.value = JSON.stringify(transaction)
    this.formTarget.requestSubmit()
  }
}

