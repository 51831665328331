import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    selector: { type: String, default: "" }
  }

  remove = () => {
    this.elementsToRemove.forEach(element => element.remove())
  }

  get elementsToRemove () {
    return this.selectorValue.length > 0 ? document.querySelectorAll(this.selectorValue) : [this.element]
  }
}
