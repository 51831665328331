import ApplicationController from "./application_controller"

// This controller is used to count the number of elements matching a selector
//
// It will dispatch a custom event with the count as a detail, eg. `element-counter:counted-3`
// if there's 3 elements matching the selector.

export default class extends ApplicationController {
  static values = {
    selector: String,
  }

  connect () {
    super.connect()

    this.observer = new MutationObserver(this.countElementsAndDispatchEvent)
    this.observer.observe(this.element, { childList: true, subtree: true, attributes: true })

    // The mutation observer doesn't support triggering on state changes, so we add it manually
    if (this.selectorValue.match(/:checked/)) {
      this.element.querySelectorAll("input").forEach(element => {
        element.addEventListener("change", this.countElementsAndDispatchEvent)
      })
    }
  }

  disconnect () {
    super.disconnect()

    this.observer.disconnect()

    if (this.selectorValue.match(/:checked/)) {
      this.element.querySelectorAll("input").forEach(element => {
        element.removeEventListener("change", this.countElementsAndDispatchEvent)
      })
    }
  }

  // Private

  countElementsAndDispatchEvent = () => {
    const count = this.element.querySelectorAll(this.selectorValue).length

    const event = new CustomEvent(`element-counter:counted-${count}`, { detail: { count }, bubbles: true })
    this.element.dispatchEvent(event)
  }
}
