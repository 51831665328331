import { BasePlugin } from "@uppy/core"

import FileStore from "./global_cache/file_store"

export default class extends BasePlugin {
  constructor (uppy, opts = {}) {
    super(uppy, opts)

    this.id = opts.id || "GlobalCache"
    this.type = "cache"
  }

  install () {
    super.install()

    FileStore.set(this.uppyId, this.uppy)
  }

  uninstall () {
    super.uninstall()

    FileStore.remove(this.uppyId)
  }

  // Private

  get uppyId () {
    return this.uppy.opts.id
  }
}
