import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    message: String
  }

  show () {
    alert(this.messageValue)
  }
}
