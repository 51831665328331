import confetti from "canvas-confetti"

import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static values = {
    fireOnConnect: { type: Boolean, default: false },
  }

  connect () {
    super.connect()

    if (this.fireOnConnectValue) {
      this.fire()
    }
  }

  fire () {
    confetti({
      particleCount: 150,
      spread: 70,
      origin: { y: 1.3 }
    })
  }
}
