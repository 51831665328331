import { loadScript } from "../helpers"

const SCRIPT_URL = "https://cdn.by.wonderpush.com/sdk/1.1/wonderpush-loader.min.js"

class WonderPushWrapper {
  constructor () {
    window.WonderPush = window.WonderPush || []
  }

  async load (options) {
    if (this.loadingPromise) { return this.loadingPromise }

    return this.loadingPromise = new Promise(async (resolve) => {
      await loadScript(SCRIPT_URL)

      this.init(options)

      WonderPush.push(resolve)
    })
  }

  init (options) {
    WonderPush.push(["init", {
      customDomain: document.location.origin,
      ...options
    }]) // Must be called with the Array-syntax
  }

  async subscribe (event) {
    await this.load()

    if (!(await this.isAvailable())) {
      // WebPush is not supported by device or the user has denied the request
      return false
    }

    await WonderPush.subscribeToNotifications(event)

    const installationId = await this.getInstallationId()

    return installationId
  }

  async getInstallationId () {
    return await WonderPush.getInstallationId()
  }

  async isAvailable () {
    const isSupported = await this.isSupported()
    return isSupported && !this.isDenied()
  }

  async isSubscribed () {
    return await WonderPush.isSubscribedToNotifications()
  }

  async isSupported () {
    const browserSupport = ("Notification" in window)
    if (!browserSupport) { return false }

    await this.load()

    return WonderPush.isNativePushNotificationSupported()
  }

  isDenied () {
    return Notification.permission === "denied"
  }
}

export default new WonderPushWrapper()
