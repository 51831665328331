import ApplicationController from "./application_controller"
import { nextFrame } from "../helpers"

export default class extends ApplicationController {
  async connect () {
    super.connect()

    await nextFrame()

    const event = new CustomEvent("mounted", { bubbles: true })
    this.element.dispatchEvent(event)
  }

  async disconnect() {
    super.disconnect()

    await nextFrame()

    const event = new CustomEvent("unmounted", { bubbles: true })
    this.element.dispatchEvent(event)
  }
}
