import ApplicationController from "./application_controller"
import { nextFrame, transitionComplete } from "../helpers"

/*
  Controller to handle enter and leave transitions for elements.

  ## Usage
  <div
    data-controller="transition"
    data-transition-enter-class="transition ease-out duration-300"
    data-transition-enter-from-class="opacity-0"
    data-transition-enter-to-class="opacity-100"
    data-transition-leave-class="transition ease-in duration-200"
    data-transition-leave-from-class="opacity-100"
    data-transition-leave-to-class="opacity-0"
  >

  The above example will apply the following classes to the element when it is
  connected: `transition ease-out duration-300 opacity-0`. Then, on the next frame,
  it will remove the `opacity-0` class and add the `opacity-100` class. Finally,
  it will remove the `transition ease-out duration-300` classes.

  For the leave transition, the `opacity-100` class will be removed and the
  `opacity-0` class will be added. Then, the `transition ease-in duration-200`
  classes will be removed.

  ## Removing an element

  The controller ***monkey-patches** the `remove` method on the element to
  automatically run the leave transition before removing the element from the DOM.

  This can be disabled by passing `true` to the `remove` method, like so:

  ```js
    element.remove(true) # will not wait for the leave transition
  ```

  ## Events
  The controller will dispatch the following events on the element:

  - `transition:enter-complete` when the enter transition is complete
  - `transition:leave-complete` when the leave transition is complete

*/

export default class extends ApplicationController {
  static classes = [ "enter", "enterFrom", "enterTo", "leave", "leaveFrom", "leaveTo" ]

  connect() {
    super.connect()

    this.attachEventListener(this.element, "turbo:before-morph-attribute", (event) => {
      const { detail } = event

      // We'd to prevent removing the `data-transition-entered` attribute when a morph if performed
      // as this would cause the element to go back to it's pre-entering state. Since the element
      // has already appeared on the page, just keep it as-is by cancelling it.
      if (detail.attributeName === "data-transition-entered") {
        event.preventDefault()
      }
    })

    if (!this.element.getAttribute("data-transition-entered")) {
      this.enter()
    }

    this.patchRemove()
  }

  disconnect() {
    super.disconnect()

    this.restoreRemove()
  }

  enter = async () => {
    await this.runTransition("enter")
    this.dispatchCompletion("enter")

    this.element.setAttribute("data-transition-entered", true)
  }

  leave = async () => {
    await this.runTransition("leave")
    this.dispatchCompletion("leave")

    this.element.setAttribute("data-transition-entered", null)
  }

  // Private

  patchRemove = () => {
    this.element.originalRemove = this.element.remove
    this.element.remove = async (skipTransition) => {
      if (!skipTransition) {
        await this.leave()
      }
      this.element.originalRemove()
    }
  }

  restoreRemove = () => {
    this.element.remove = this.element.originalRemove
    delete this.element.originalRemove
  }

  runTransition = async (direction) => {
    const classes = this.getTransitionClasses(`${direction}`)
    const fromClasses = this.getTransitionClasses(`${direction}From`)
    const toClasses = this.getTransitionClasses(`${direction}To`)

    this.element.classList.add(...classes)
    this.element.classList.add(...fromClasses)

    await nextFrame();

    this.element.classList.remove(...fromClasses)
    this.element.classList.add(...toClasses)

    await transitionComplete(this.element)

    this.element.classList.remove(...toClasses)
    this.element.classList.remove(...classes)
  }

  getTransitionClasses = (name) => {
    if (!this[`has${capitalize(name)}Class`]) { return [] }

    return this[`${name}Classes`]
  }

  dispatchCompletion = (direction) => {
    const eventName = `transition:${direction}-complete`
    const event = new CustomEvent(eventName, { bubbles: true })
    this.element.dispatchEvent(event)
  }
}

function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}
