import ApplicationController from "./application_controller"

import EmblaCarousel from "embla-carousel"

export default class extends ApplicationController {
  static targets = ["next", "prev", "viewport"]
  static values = {
    startIndex: { type: Number, default: 0 },
    options: { type: Object, default: {} }
  }

  connect () {
    super.connect()

    this._swiper = EmblaCarousel(this.viewportTarget, this.options)

    this._swiper.on("init", this.updateNavigationTargets)
    this._swiper.on("select", this.updateNavigationTargets)
  }

  disconnect () {
    super.disconnect()
  }

  next () {
    this._swiper.scrollNext()
  }

  prev () {
    this._swiper.scrollPrev()
  }

  updateNavigationTargets = () => {
    if (this.hasNextTarget) {
      this.nextTarget.ariaDisabled = !this.canScrollNext
    }

    if (this.hasPrevTarget) {
      this.prevTarget.ariaDisabled = !this.canScrollPrev
    }
  }

  get canScrollNext () {
    return this._swiper.canScrollNext()
  }

  get canScrollPrev () {
    return this._swiper.canScrollPrev()
  }

  get options () {
    const defaultOptions = {
      slidesToScroll: "auto",
      active: true
    }

    return {
      ...defaultOptions,
      startIndex: this.startIndexValue,
      ...this.optionsValue,
    }
  }
}
