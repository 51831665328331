import { BridgeElement } from "@hotwired/strada"

import ApplicationBridgeComponent from "./application_bridge_component"

export default class extends ApplicationBridgeComponent {
  static component = "action-sheet"
  static targets = [ "title", "action" ]

  connect () {
    super.connect()

    this.showActionSheetMenu()
  }

  showActionSheetMenu() {
    const actions = this.actions
    const options = actions.map(action => action.title)
    const destructiveButtonIndex = actions.findIndex(action => action.destructive)
    const cancelButtonIndex = actions.findIndex(action => action.cancel)

    this.send("show", {
      title: this.title,
      options,
      cancelButtonIndex,
      destructiveButtonIndex,
    }, ({ data: { selectedIndex }}) => {
      const action = actions[selectedIndex]

      if (action?.click) {
        action.click()
        this.close()
      }
    })
  }

  get title () {
    if (this.hasTitleTarget) {
      return new BridgeElement(this.titleTarget).title
    }
  }

  get actions () {
    return [
      ...this.actionTargets.map(element => this.makeAction(element)),
      this.cancelAction,
    ].filter(action => action)
  }

  get cancelAction () {
    return {
      title: "Cancel", // TODO: Ensure we can localize this; get it from HTML?
      cancel: true,
      click: () => this.close(),
    }
  }

  close () {
    this.element.remove()
  }

  makeAction(element) {
    const bridgeElement = new BridgeElement(element)

    if (bridgeElement.disabled) return null

    return {
      title: bridgeElement.title,
      destructive: bridgeElement.bridgeAttribute("destructive") === "true",
      click: () => bridgeElement.click(),
    }
  }
}
