import AbstractPaymentMethodController from "./abstract_payment_method_controller"

// See: https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_on_the_web_version_history
// For now, there's no specific features of v 5+ that we want to support, so we use 5 to get as many iOS versions
// with support as possible.
const APPLE_PAY_VERSION = 5

export default class extends AbstractPaymentMethodController {
  static targets = ["container"]
  static values = { session: Object }

  async connect () {
    super.connect()

    this.initializing()

    this.adyenCheckout = await this.getAdyenCheckout()

    await this.mountApplePay()

    this.ready()
  }

  async mountApplePay () {
    const applePayConfiguration = {
      onAuthorized: this.onAuthorized,
      onClick: this.onClick,
    }

    const applePayComponent = this.adyenCheckout.create("applepay", applePayConfiguration)
    await applePayComponent.isAvailable()

    this.mountedTarget = applePayComponent.mount(this.containerTarget)
  }

  onClick = (resolve, reject) => {
    const { options: { session, processingCountryCode, merchantName } } = this.adyenCheckout

    const request = {
      // This is the *merchant* country code and is used to determine eg. if 3DS is chosen.
      // See: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/1916117-countrycode
      //
      // The countryCode here is different than the one provided from/to Adyen, ie. this one follows ISO standards
      // while the Adyen country code follows EU standard (ie. GB here, but UK for Adyen)
      countryCode: processingCountryCode,
      currencyCode: session.amount.currency,
      supportedNetworks: ["visa", "masterCard", "electron", "maestro"],
      merchantCapabilities: ["supports3DS"],
      total: {
        label: merchantName,
        amount: session.amount.value,
      },
    }

    const applePaySession = new window.ApplePaySession(APPLE_PAY_VERSION, request)

    resolve(applePaySession)
  }

  onAuthorized = async (resolve, reject, event) => {
    try {
      const token = event.payment.token.paymentData

      if (token) {
        this.success({ apple_pay: { token } })
        resolve()
      }
    } catch(error) {
      this.complete()
      reject()
    }
  }
}
