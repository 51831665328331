import { Autocomplete } from "stimulus-autocomplete"

import withEventAttachable from "./mixins/withEventAttachable"

class AutocompleteController extends Autocomplete {
  connect () {
    super.connect()

    this.attachEventListener(this.element, "loadstart", this.onLoadStart.bind(this))
    this.attachEventListener(this.element, "loadend", this.onLoadEnd.bind(this))
  }

  dispatchChangeEvent () {
    const event = new Event("change")
    this.hiddenTarget.dispatchEvent(event)
  }

  onInputChange () {
    super.onInputChange()

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = ""
      this.dispatchChangeEvent()
    }
  }

  onLoadStart () {
    this.element.classList.add("loading")
  }

  onLoadEnd () {
    this.element.classList.remove("loading")
  }
}

export default withEventAttachable(AutocompleteController)
