import { loadScript } from "../helpers"

const SDK_URL = "https://js.chargebee.com/v2/chargebee.js"

class Chargebee {
  async ready () {
    if (window.Chargebee) {
      return window.Chargebee
    }

    return await this.load()
  }

  async load () {
    await loadScript(SDK_URL)

    Object.assign(this, window.Chargebee)

    return window.Chargebee
  }
}

export default new Chargebee()
