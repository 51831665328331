import TextareaAutogrow from "stimulus-textarea-autogrow"
import { delay } from "../helpers"

export default class extends TextareaAutogrow {
  connect () {
    super.connect()

    // Override behavior from stimulus-textarea-autogrow, where the `overflow` property
    // is set to 'hidden' if we have a `max-height` set.  This is not what we want,
    // as it will hide the scrollbar.  We want to keep the scrollbar visible, so we
    // can use it to scroll the textarea.
    const maxHeight = getComputedStyle(this.element).maxHeight
    if (maxHeight) {
      this.element.style.overflow = "auto"
    }

    if (this.formElement) {
      this.formElement.addEventListener("reset", this.reset)
    }
  }

  disconnect () {
    super.disconnect()

    if (this.formElement) {
      this.formElement.removeEventListener("reset", this.reset)
    }
  }

  autogrow () {
    super.autogrow()

    document.dispatchEvent(new Event("autogrow"))
  }

  reset = async () => {
    await delay()

    this.autogrow()
  }

  get formElement () {
    return this.element.closest("form")
  }
}
