import Chargebee from "../../lib/chargebee"
import AdyenCheckout from "../../lib/adyen/checkout"
import Request from "../../lib/http/request"
import ApplicationController from "../application_controller"

import { NAMESPACE } from "./abstract_payment_method_controller"

export default class extends ApplicationController {
  static targets = ["completionParams", "form"]
  static values = { adyenConfigurationUrl: String, siteId: String, publishableKey: String }

  paymentMethodControllers = []

  async connect () {
    super.connect()

    this.attachEventListener(this.element, `${NAMESPACE}:connected`, this.registerPaymentMethodController)
    this.attachEventListener(this.element, `${NAMESPACE}:disconnected`, this.unregisterPaymentMethodController)
    this.attachEventListener(this.element, `${NAMESPACE}:success`, this.completePaymentIntent)
  }

  registerPaymentMethodController = (event) => {
    const { controller } = event.detail

    this.paymentMethodControllers = [...this.paymentMethodControllers, controller]
  }

  unregisterPaymentMethodController = (event) => {
    const { controller } = event.detail

    this.paymentMethodControllers = this.paymentMethodControllers.filter(c => c != controller)
  }

  disable () {
    this.paymentMethodControllers.map(controller => controller.disable())
  }

  enable () {
    this.paymentMethodControllers.map(controller => controller.enable())
  }

  completePaymentIntent = (event) => {
    this.disable()

    this.completionParamsTarget.value = JSON.stringify(event.detail)
    this.formTarget.submit()

    this.element.classList.add("submitting")
  }

  async getAdyenCheckout () {
    if (!this.adyenCheckout) {
      const configuration = await this.getAdyenConfiguration()
      this.adyenCheckout = await AdyenCheckout(configuration)
    }

    return this.adyenCheckout
  }

  getAdyenConfiguration = async  () => {
    const request = new Request("GET", this.adyenConfigurationUrlValue, { responseKind: "json" })
    const response = await request.perform()
    const json = await response.json()

    return json
  }

  async getChargebeeInstance () {
    if (!this.chargebeeInstance) {
      await Chargebee.ready()

      this.chargebeeInstance = window.Chargebee.init({
        site: this.siteIdValue,
        publishableKey: this.publishableKeyValue
      })
    }

    return this.chargebeeInstance
  }
}
