import { Controller } from "@hotwired/stimulus"

const SESSION_STORAGE_KEY = "canGoBackWithHistory"
const SESSION_STORAGE_VALUE = "true"

export default class extends Controller {
  canGoBackWithHistory = false

  connect () {
    document.addEventListener("turbo:load", this.didNavigateToPage)

    this.canGoBackWithHistory = sessionStorage.getItem(SESSION_STORAGE_KEY) == SESSION_STORAGE_VALUE
  }

  disconnect () {
    document.removeEventListener("turbo:load", this.didNavigateToPage)
  }

  goBack (event) {
    if (this.canGoBackWithHistory) {
      history.go(-1)

      event.preventDefault()
      return false
    }
  }

  didNavigateToPage = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, SESSION_STORAGE_VALUE)
  }
}
