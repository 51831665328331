import ApplicationBridgeComponent from "./application_bridge_component"

export default class extends ApplicationBridgeComponent {
  static component = "store-review"

  requestReview = () => {
    console.log("requestReview")

    this.send("requestReview")
  }
}
