import ApplicationController from "../application_controller"

export default class extends ApplicationController {
  connect () {
    super.connect()

    if (!window.ApplePaySession) {
      this.element.classList.add("unavailable")
      this.element.ariaHidden = true
    }
  }
}
