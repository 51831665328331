import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  reload () {
    // We fallback to document.baseURI if there's no explicit `src` provided in the frame
    // This will trigger a reload of the frame to the current URL.
    this.frameElement.src = document.baseURI || this.frameElement.src
  }

  get frameElement () {
    return this.element.closest("turbo-frame")
  }
}
