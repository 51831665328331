import ApplicationController from "./application_controller"
import Cookies from "js-cookie"
import { new_feedback_rating_path } from "../helpers/routes"

const SIGNIFICANT_EVENTS_COUNT_COOKIE_NAME = "fr_events"
const RECENTLY_PROMPTED_COOKIE_NAME = "fr_prompted"
const WILL_PROMPT_FOR_FEEDBACK_SESSION_KEY = "willPromptForFeedback"
const COOKIE_EXPIRATION_DAYS = 365

export default class extends ApplicationController {
  trackSignificantEvent () {
    const count = this.significantEventsCount + 1

    Cookies.set(SIGNIFICANT_EVENTS_COUNT_COOKIE_NAME, count, { expires: COOKIE_EXPIRATION_DAYS })

    if (count % 5 === 0 && !this.recentlyPrompted) {
      sessionStorage.setItem(WILL_PROMPT_FOR_FEEDBACK_SESSION_KEY, "true")
    }
  }

  maybePromptForFeedback () {
    const willPromptForFeedback = sessionStorage.getItem(WILL_PROMPT_FOR_FEEDBACK_SESSION_KEY)
    sessionStorage.removeItem(WILL_PROMPT_FOR_FEEDBACK_SESSION_KEY)

    if (willPromptForFeedback === "true") {
      this.promptForFeedback()
    }
  }

  promptForFeedback () {
    const dialogUrl = new_feedback_rating_path()
    this.dialogFrame.setAttribute("src", dialogUrl)

    this.setPrompted()
  }

  // Private

  setPrompted () {
    Cookies.set(RECENTLY_PROMPTED_COOKIE_NAME, "true", { expires: COOKIE_EXPIRATION_DAYS })
  }

  get recentlyPrompted () {
    return Cookies.get(RECENTLY_PROMPTED_COOKIE_NAME) === "true"
  }

  get significantEventsCount () {
    return parseInt(Cookies.get(SIGNIFICANT_EVENTS_COUNT_COOKIE_NAME) || 0)
  }

  get dialogFrame () {
    return document.getElementById("dialog")
  }
}
