import ApplicationController from "./application_controller"
import WonderPush from "../lib/wonder_push"
import { current } from "../helpers"
import { asPushable } from "./mixins/pushable"

class WonderPushController extends ApplicationController {
  async connect () {
    super.connect()

    await this.#loadWonderPush()
    await this.#persistExistingSubscription()
  }

  async subscribe (event) {
    const installationId = await WonderPush.subscribe(event)

    if (!installationId) {
      return false
    }

    return await this.persistPushable({
      installation_id: installationId,
    })
  }

  async isAvailable () {
    return await WonderPush.isAvailable()
  }

  async isSubscribed() {
    return await WonderPush.isSubscribed()
  }

  async canBeAsked () {
    const available = await this.isAvailable()
    if (!available) { return false }

    const subscribed = await this.isSubscribed()

    return !subscribed
  }

  async #loadWonderPush () {
    WonderPush.init({
      webKey: current.wonderPush.websiteId,
      userId: current.user.id
    })
  }

  async #persistExistingSubscription () {
    const available = await this.isAvailable()
    if (!available) { return }

    const installationId = await WonderPush.getInstallationId()

    if (installationId) {
      await this.persistPushable({
        installation_id: installationId
      })
    }
  }
}

export default asPushable("WonderPushToken", WonderPushController)
