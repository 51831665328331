import ApplicationController from "./application_controller"
import Color from "color"

/* This controller allows to set the theme-color meta tag of the page to the elements background color. */

export default class extends ApplicationController {
  connect () {
    super.connect()

    this._previousColor = this.metaElement.getAttribute("content")
    this.metaElement.setAttribute("content", this.color)
  }

  disconnect () {
    super.disconnect()

    this.metaElement.setAttribute("content", this._previousColor)
  }

  get color () {
    const elementBackgroundColor = window.getComputedStyle(this.element).backgroundColor

    let color = Color(elementBackgroundColor)

    if (color.alpha() < 1) {
      const bodyBackgroundColor = window.getComputedStyle(document.body).backgroundColor
      const bodyColor = Color(bodyBackgroundColor)
      const alpha = color.alpha()

      color = bodyColor.mix(color.alpha(1), alpha)
    }

    return color.hex()
  }

  get metaElement () {
    const meta = document.querySelector("meta[name='theme-color']") || this.createMetaElement()

    return meta
  }

  createMetaElement () {
    const meta = document.createElement("meta")
    meta.setAttribute("name", "theme-color")

    const head = document.getElementsByTagName("head")
    if (head) {
      head[0].appendChild(meta)
    }

    return meta
  }
}
