import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect () {
    this.observer = new IntersectionObserver(this.didIntersect, { threshold: [1] })
    this.observer.observe(this.element)
  }

  disconnect () {
    this.observer.disconnect()
  }

  didIntersect = ([entry]) => {
    this.element.classList.toggle("stuck", entry.intersectionRatio < 1)
  }
}
