import ApplicationController from "./application_controller"

// Controller that allows us to add or remove classes from an element.
// Useful for attaching to turbo:submit-* events.
//
// The class name and target(s) should be provided as params, eg:
// <form
//   data-controller="class-list"
//   data-action="turbo:submit-error->class-list#add"
//   data-class-list-name-param="invalid"
//   data-class-list-target-param="body"
// >..</form>
//
// The supported params are:
//
//  name: The name of the class to be added or removed
//  target: The ID of the element to add/remove the class to
//  targets: A selector of elements to add/remove the class to
//
// If neither target or targets is provided, the target will be the event.target (ie. form)

export default class extends ApplicationController {
  add (event) {
    const { params: { name } } = event
    const targets = this.getTargetElementsFromEvent(event)

    for (const target of targets) {
      target.classList.add(name)
    }
  }

  remove (event) {
    const { params: { name } } = event
    const targets = this.getTargetElementsFromEvent(event)

    for (const target of targets) {
      target.classList.remove(name)
    }
  }

  // Private

  getTargetElementsFromEvent (event) {
    const { params: { target, targets } } = event

    if (target) {
      return this.getTargetElementsById(target)
    } else if (targets) {
      return this.getTargetElementsByQuery(targets)
    } else {
      return [event.target]
    }
  }

  getTargetElementsByQuery (targets) {
    const elements = document.querySelectorAll(targets)

    if (elements.length !== 0) {
      return Array.prototype.slice.call(elements)
    } else {
      return []
    }
  }

  getTargetElementsById (target) {
    const element = document.getElementById(target)

    if (element !== null) {
      return [element]
    } else {
      return []
    }
  }
}
