import MorphRenderer from "../lib/turbo/morph_renderer"

addEventListener("turbo:before-render", ({ detail }) => {
  if (detail.renderMethod === "morph") { return }

  const originalRender = detail.render

  detail.render = async (existingBody, newBody) => {
    const renderer = new MorphRenderer(existingBody, newBody)
    renderer.render(originalRender)
  }
})
