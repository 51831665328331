import ApplicationBridgeComponent from "./application_bridge_component"
import { current } from "../../helpers"

export default class extends ApplicationBridgeComponent {
  static component = "current"

  connect () {
    super.connect()

    this.updateCurrent()
  }

  updateCurrent () {
    this.send("update", this.currentValue)
  }

  get currentValue () {
    return {
      user: current.user
    }
  }
}
