import ApplicationController from "./application_controller"

const zeroPad = (num, places) => String(num).padStart(places, "0")

export default class extends ApplicationController {
  connect () {
    super.connect()

    this.updateText()
    this.start()
  }

  start = () => {
    this.interval = setInterval(() => {
      this.updateText()
      this.checkForCompletion()
    }, 1000)
  }

  stop = () => {
    clearInterval(this.interval)
  }

  finish = () => {
    this.stop()

    const event = new CustomEvent("countdown:finish", { bubbles: true })
    this.element.dispatchEvent(event)
  }

  checkForCompletion = () => {
    if (this.timeRemaining.total <= 0) {
      this.finish()
    }
  }

  updateText () {
    const remaining = this.timeRemaining

    if (remaining.total <= 0) {
      return
    }

    if (remaining.days > 0) {
      remaining.hours = remaining.hours + remaining.days * 24
    }

    this.element.innerText = [remaining.hours, remaining.minutes, remaining.seconds].map((n) => zeroPad(n, 2)).join(":")
  }

  get timeRemaining () {
    const total = this.data.get("until") - Date.now() / 1000

    const seconds = Math.floor(total % 60)
    const minutes = Math.floor((total / 60) % 60)
    const hours = Math.floor((total / (60 * 60)) % 24)
    const days = Math.floor(total / (60 * 60 * 24))

    return {
      total: total,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    }
  }
}
