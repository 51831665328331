import ApplicationBridgeComponent from "./application_bridge_component"

export default class extends ApplicationBridgeComponent {
  static component = "badges"
  static values = { count: Object }

  connect() {
    super.connect()
    this.updateBadges()
  }

  updateBadges() {
    this.send("update", this.countValue)
  }
}
