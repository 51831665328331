// This is a somewhat hacky way of forcing Turbo to *never* show a preloaded page as part
// of a *restoration* visit. See also how we set `x-sec-purpose` meta tag in our layout file.
//
// This fixes the following issue:
//   1. User saves form and recedes back to previous page. This clears the Turbo cache.
//   2. The next pages preloads multiple links, one of these is a link that is in the history stack (Page B)
//   3. User presses back to Page B. In this case, the *preloaded* page (with placeholders) would be shown.
//
// With this fix, we force a visit with fresh content if user ever navigates back to a preloaded page with placeholders.

addEventListener("turbo:load", () => {
  if (document.head.querySelector("meta[name='x-sec-purpose']")?.content == "preload") {
    // Skip the redirect if we're forcing a preload
    if (document.location.href.match(/force_preload/)) {
      return
    }

    Turbo.session.refresh(document.baseURI)
  }
})
