import ApplicationController from "./application_controller"
import Cookies from "js-cookie"

import { withPushable } from "./mixins/pushable"
import { new_push_token_path } from "../helpers/routes"

const PROMPTED_COOKIE_NAME = "web_push_prompted"
const PERMISSION_COOKIE_NAME = "web_push_permission"

class PushTokenPromptController extends ApplicationController {
  // Soft-prompt the user. The dialog will not be shown:
  //
  // * If the user has recently denied the soft prompt
  // * If the user is already subscribed or
  // * If the user has already been prompted recently.
  //
  // This method is typically called upon some
  // positive user interaction such as creating a match request etc.
  async prompt () {
    if (!await this.canBePrompted()) { return }

    await this.showPrompt()
  }

  // Force-show the prompt, ie. in reaction to an explicit user request to opt-in to push.
  async showPrompt () {
    if (!this.dialogFrame) { return }

    const url = new_push_token_path()
    this.dialogFrame.setAttribute("src", url)

    this.setPrompted()
  }

  get dialogFrame () {
    return document.getElementById("dialog")
  }

  async canBePrompted () {
    return (
      !this.hasPrompted && // We must not have prompted the user recently
      !this.hasDeniedPrompt && // The user must not have denied the prompt recently
      await this.pushable?.canBeAsked() // The pushable must be ready to be asked
    )
  }

  get hasPrompted () {
    // Determines if we have shown the prompt recently
    return Cookies.get(PROMPTED_COOKIE_NAME)
  }

  async subscribe (originalEvent) {
    const result = await this.pushable?.subscribe(originalEvent)

    if (result) {
      const event = new CustomEvent("push-token-prompt:subscribed", { bubbles: true })
      this.element.dispatchEvent(event)
    }
  }

  setPrompted () {
    // We can prompt once a day
    Cookies.set(PROMPTED_COOKIE_NAME, "true", { expires: 1 })
  }

  get hasDeniedPrompt () {
    return Cookies.get(PERMISSION_COOKIE_NAME) === "denied"
  }

  setDeniedPrompt () {
    // We store the soft-deny for 30 days before being able to prompt again
    Cookies.set(PERMISSION_COOKIE_NAME, "denied", { expires: 30 })
  }
}

export default withPushable(PushTokenPromptController)
