import { Notifier } from "@airbrake/browser";

export default class {
  constructor({ projectId, projectKey, environment, userId }) {
    this.projectId = projectId;
    this.projectKey = projectKey;
    this.environment = environment;
    this.userId = userId;
  }

  start() {
    const notifier = new Notifier({ projectId: this.projectId, projectKey: this.projectKey, environment: this.environment });

    notifier.addFilter(notice => {
      notice.context.environment = this.environment;
      notice.context.userId = this.userId;

      if (this.environment == "development" || this.environment == "test") {
        return null
      }

      if (!this.supportsGrid()) {
        return null
      }

      return notice
    });
  }

  // Simple detection for skipping old legacy browsers, which is kinda
  // like we also use for displaying the unsupported-browser overlay:
  supportsGrid() {
    return document.createElement("div").style.grid !== undefined;
  }
}
