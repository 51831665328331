import { morphElement } from '../../helpers'
import MorphableSnapshot from './morphable_snapshot'

// A Renderer class that will extract morphable elements tagged with [data-turbo-morph] before
// rendering, and morph them back into the new snapshot after rendering, similar to how permanent
// elements are handled by Turbo. Useful for persisting horizontal scroll etc.
//
// Note: A morphable element must have an [id] attribute.

class MorphRenderer {
  constructor(existingBody, newBody) {
    this.existingSnapshot = new MorphableSnapshot(existingBody)
    this.newSnapshot = new MorphableSnapshot(newBody)
  }

  render(originalRender) {
    const morphableElementsMap = this.existingSnapshot.getMorphableElementsMapForSnapshot(this.newSnapshot)

    this.moveExistingElementToNewSnapshot(morphableElementsMap)

    originalRender(this.existingSnapshot.element, this.newSnapshot.element)

    this.morphNewElementsAfterRender(morphableElementsMap)
  }

  moveExistingElementToNewSnapshot(morphableElementsMap) {
    for (const [_, { currentElement, newElement }] of Object.entries(morphableElementsMap)) {
      const clone = currentElement.cloneNode(true)

      currentElement.replaceWith(clone)
      newElement.replaceWith(currentElement)
    }
  }

  morphNewElementsAfterRender(morphableElementsMap) {
    for (const [_, { currentElement, newElementHTML }] of Object.entries(morphableElementsMap)) {
      morphElement(currentElement, newElementHTML)
    }
  }
}

export default MorphRenderer
