// This listener adds additional granular events to Turbo.
// Specifically it will dispatch `turbo:submit-success` and `turbo:submit-error` events
// respectively after the `turbo:submit-end` event has been called.
// See also: https://github.com/hotwired/turbo/issues/96 for discussion.

class TurboEventsObserver {
  start () {
    document.addEventListener("turbo:submit-end", this.didSubmitForm)
  }

  stop () {
    document.removeEventListener("turbo:submit-end", this.didSubmitForm)
  }

  didSubmitForm = (event) => {
    const { target, detail } = event
    const { success } = detail

    if (success) {
      this.dispatch("turbo:submit-success", { target, detail, cancelable: false })
    } else {
      this.dispatch("turbo:submit-error", { target, detail, cancelable: false })
    }
  }

  dispatch (eventName, { target, cancelable, detail } = {}) {
    const event = new CustomEvent(eventName, { cancelable, bubbles: true, detail })
    target.dispatchEvent(event)

    return event
  }
}

new TurboEventsObserver().start()
