import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  clearCache () {
    Turbo.cache.clear()
  }

  visit ({ params: { url, options } }) {
    Turbo.visit(url, options)
  }

  refresh () {
    Turbo.session.refresh(document.baseURI)
  }
}
