import { BasePlugin } from "@uppy/core"

function htmlToElement (html) {
  const template = document.createElement("template")
  template.innerHTML = html
  return template.content.firstElementChild
}

export default class extends BasePlugin {
  constructor (uppy, opts = {}) {
    super(uppy, opts)
    this.id = opts.id || "Previewer"
    this.type = "previewer"
  }

  install () {
    super.install()

    this.uppy.on("file-added", this.addPreviewForFile)
    this.uppy.on("thumbnail:generated", this.didGenerateThumbnailForPreview)
    this.uppy.on("upload-progress", this.didChangeUploadProgressForFile)
    this.uppy.on("upload-error", this.didErrorWhileUploadingFile)
  }

  uninstall () {
    super.uninstall()

    this.uppy.off("file-added", this.addPreviewForFile)
    this.uppy.off("thumbnail:generated", this.didGenerateThumbnailForPreview)
    this.uppy.off("upload-progress", this.didChangeUploadProgressForFile)
    this.uppy.off("upload-error", this.didErrorWhileUploadingFile)
  }

  // Private

  addPreviewForFile = (file) => {
    const previewElement = this.generatePreviewElement(file)
    this.uppy.setFileState(file.id, { previewElement })

    this.opts.previewContainerTarget.appendChild(previewElement)
  }

  generatePreviewElement = (file) => {
    const previewElement = htmlToElement(this.previewTemplate)

    previewElement.dataset.uppyId = this.uppyId
    previewElement.dataset.uppyFileId = file.id

    return previewElement
  }

  didGenerateThumbnailForPreview = (file) => {
    const previewElement = file.previewElement

    const event = new CustomEvent("thumbnail:generated", { detail: { file }, bubbles: true })
    previewElement.dispatchEvent(event)
  }

  didChangeUploadProgressForFile = (file, progress) => {
    const previewElement = file.previewElement

    const event = new CustomEvent("uploader:progress", { detail: { file, progress }, bubbles: true })
    previewElement.dispatchEvent(event)
  }

  didErrorWhileUploadingFile = (file, error, response) => {
    file.previewElement.setAttribute("aria-invalid", true)
  }

  get previewTemplate () {
    return this.opts.previewTemplateTarget.innerHTML
  }

  get uppyId () {
    return this.uppy.opts.id
  }
}
