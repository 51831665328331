import ApplicationController from "./application_controller"
import turnstile from "../lib/turnstile"
import { current } from "../helpers"

export default class extends ApplicationController {
  static targets = ["placeholder", "container", "input"]
  static values = {
    // These map to the values found at https://developers.cloudflare.com/turnstile/get-started/client-side-rendering/#configurations
    siteKey: String,
    action: String,
    appearance: { type: String, default: "execute" },
    theme: { type: String, default: "auto" }
  }

  static ready = false;
  static completed = false
  static submitRequested = false

  async connect() {
    super.connect()

    if (this.hasPlaceholderTarget) {
      await this.setup()
    } else {
      console.warn("No placeholder target found for captcha controller. Captcha will not be initialized.")
    }
  }

  setup = async () => {
    await turnstile.loaded()

    turnstile.render(this.placeholderTarget, {
      sitekey: this.siteKey,
      theme: 'light',
      action: this.actionValue,
      callback: this.didComplete,
      theme: this.themeValue,
      appearance: this.appearanceValue,
      'before-interactive-callback': this.willRequireInteraction,
      'error-callback': this.didError,
      'unsupported-callback': this.isNotSupported,
    })

    this.ready = true
  }

  show = () => {
    if (this.hasContainerTarget) {
      this.containerTarget.ariaExpanded = true
    }

    const event = new CustomEvent("captcha:show", { bubbles: true })
    this.element.dispatchEvent(event)
  }

  willRequireInteraction = () => {
    this.show()

    const event = new CustomEvent("captcha:before-interaction", { bubbles: true })
    this.element.dispatchEvent(event)
  }

  didError = (error) => {
    this.ready = false

    this.show()

    const event = new CustomEvent("captcha:error", { bubbles: true, detail: { error } })
    this.element.dispatchEvent(event)
  }

  isNotSupported = () => {
    if (this.hasContainerTarget) {
      this.containerTarget.ariaInvalid = true
    }

    this.ready = false

    const event = new CustomEvent("captcha:not-supported", { bubbles: true })
    this.element.dispatchEvent(event)
  }

  didComplete = (token) => {
    if (this.hasInputTarget) {
      this.inputTarget.value = token
    }

    this.completed = true

    const event = new CustomEvent("captcha:complete", { bubbles: true, detail: { token } })
    this.element.dispatchEvent(event)
  }

  get siteKey () {
    return this.siteKeyValue || current.turnstile.siteKey
  }
}
