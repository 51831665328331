import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["input", "total"]
  static values = { currencyUnit: String, format: String }

  connect () {
    super.connect()

    this.update()
  }

  update () {
    if (this.hasTotalTarget) {
      this.totalTarget.dataset.moneyCentsValue = this.totalCents
    }
  }

  // Private

  get activeTotals () {
    return this.inputTargets.filter((input) => input.checked).map((input) => parseInt(input.dataset.totalsCents))
  }

  get totalCents () {
    return this.activeTotals.reduce((total, value) => total + value, 0)
  }
}
