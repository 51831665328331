import ThumbnailGenerator from "@uppy/thumbnail-generator"

export default class extends ThumbnailGenerator {
  install () {
    super.install()

    this.uppy.on("image-editor:complete", this.onFileAdded)
  }

  uninstall () {
    super.uninstall()

    this.uppy.off("image-editor:complete", this.onFileAdded)
  }
}
