import { Turbo } from "@hotwired/turbo-rails";

// This observer is used to refresh the session when the app is brought back to the foreground
// after being in the background for a while.
//
// The TTL is set in a meta tag in the head of the document, eg:
//
//   <meta name="turbo-native-app-state-refresh-ttl" content="300">
//
// ... which will refresh the session if the app was backgrounded for more than 300 seconds.

class TurboNativeAppStateObserver {
  static metaSelector = 'meta[name="turbo-native-app-state-refresh-ttl"]'

  constructor() {
    this.focused = true
    this.appState = null
    this.appStateChangedAt = new Date()
  }

  start() {
    document.addEventListener('turbo-native:screen-blur', this.didBlur);
    document.addEventListener('turbo-native:screen-focus', this.didFocus);
    document.addEventListener('turbo-native:app-state-change', this.didChangeAppState);
  }

  stop() {
    document.removeEventListener('turbo-native:screen-blur', this.didBlur);
    document.removeEventListener('turbo-native:screen-focus', this.didFocus);
    document.removeEventListener('turbo-native:app-state-change', this.didChangeAppState);
  }

  didBlur = () => {
    this.focused = false
  }

  didFocus = () => {
    this.focused = true
  }

  didChangeAppState = ({ detail }) => {
    if (this.focused && this.appState === 'background' && detail.appState === 'active') {
      if (this.secondsSinceAppStateChanged >= this.ttl) {
        this.refresh()
      }
    }

    this.appState = detail.appState
    this.appStateChangedAt = new Date()
  }

  refresh = () => {
    Turbo.session.refresh(document.baseURI)
  }

  get ttl() {
    const meta = document.querySelector(this.constructor.metaSelector)
    return meta ? parseInt(meta.getAttribute('content')) : Infinity
  }

  get secondsSinceAppStateChanged() {
    return (new Date() - this.appStateChangedAt) / 1000
  }
}

export default TurboNativeAppStateObserver
