import AutocompleteController from "./autocomplete_controller"

export default class extends AutocompleteController {
  static targets = ["countryCode"]

  connect () {
    super.connect()

    this.countryCodeTarget.addEventListener("change", this.onCountryCodeChange.bind(this))
  }

  focus () {
    this.inputTarget.focus()
  }

  onCountryCodeChange = () => {
    this.resetInput()
    this.focus()
  }

  resetInput () {
    this.inputTarget.value = ""
    this.onInputChange()
  }

  select () {
    this.inputTarget.select()
  }

  buildURL (query) {
    const builtURL = super.buildURL(query)
    const url = new URL(builtURL, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))

    params.append("country_code", this.countryCode)
    url.search = params.toString()

    return url.toString()
  }

  get countryCode () {
    return this.countryCodeTarget.value
  }
}
