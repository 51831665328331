import { openDefaultEditor } from "@pqina/pintura"
import "@pqina/pintura/pintura.css"

import { UIPlugin } from "@uppy/core"

export default class extends UIPlugin {
  constructor (uppy, opts = {}) {
    super(uppy, opts)

    this.id = opts.id || "ImageEditorWithPinturaPlugin"
    this.type = "preprocessor"
  }

  install () {
    this.uppy.addPreProcessor(this.preProcessFiles)
  }

  uninstall () {
    this.uppy.removePreProcessor(this.preProcessFiles)
  }

  // Private

  preProcessFiles = (fileIds) => {
    if (this.opts.enabled === false) {
      return Promise.resolve()
    }

    const editorPromises = fileIds.map(id => {
      const file = this.uppy.getFile(id)
      return this.editImageFile(file)
    })

    return Promise.allSettled(editorPromises)
  }

  editImageFile = (file) => {
    return new Promise((resolve, reject) => {
      this.uppy.emit("image-editor:start", file)

      const editor = this.openEditorForImage(file.data)

      editor.on("process", (imageState) => {
        this.uppy.setFileState(file.id, {
          data: imageState.dest,
          preview: null
        })

        const editedFile = this.uppy.getFile(file.id)

        this.uppy.emit("image-editor:complete", editedFile)
        resolve()
      })

      editor.on("close", () => {
        this.uppy.emit("image-editor:cancel", file)
        resolve()
      })
    })
  }

  openEditorForImage = (file) => {
    return openDefaultEditor({
      src: file,
      imageCropMinSize: { width: 300, height: 300 },
      imageCropAspectRatio: 3 / 4,
      utils: [
        "crop",
        "filter",
        "finetune"
      ],
    })
  }
}
