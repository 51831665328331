import ApplicationController from "./application_controller"
import FileStore from "./uploader/global_cache/file_store"

export default class extends ApplicationController {
  static targets = ["previewImage", "progress"]

  connect () {
    this.element.addEventListener("thumbnail:generated", this.didGenerateThumbnail)
    this.element.addEventListener("uploader:progress", this.didChangeUploadProgressForFile)

    this.setupPreviewWithUppyPreviewIfAvailable()
  }

  disconnect () {
    this.element.removeEventListener("thumbnail:generated", this.didGenerateThumbnail)
    this.element.removeEventListener("uploader:progress", this.didChangeUploadProgressForFile)
  }

  // Private

  setupPreviewWithUppyPreviewIfAvailable () {
    if (!this.uppyFile?.preview) { return }

    this.previewImageTarget.src = this.uppyFile.preview
  }

  didChangeUploadProgressForFile = (event) => {
    const { bytesUploaded, bytesTotal } = event.detail.progress
    const percentage = Math.round(bytesUploaded / bytesTotal * 100)

    this.updateElementProgressAriaAttributes(this.element, percentage)

    if (this.hasProgressTarget) {
      this.updateElementProgressAriaAttributes(this.progressTarget, percentage)
    }
  }

  updateElementProgressAriaAttributes = (element, percentage) => {
    element.setAttribute("aria-busy", !percentage === 100)
    element.setAttribute("aria-valuenow", percentage)
    element.setAttribute("aria-valuetext", `${percentage}%`)
    element.setAttribute("aria-valuemax", 0)
    element.setAttribute("aria-valuemax", 100)

    element.style.setProperty("--upload-progress", `${percentage}%`)
  }

  didGenerateThumbnail = (event) => {
    const { detail: { file: { preview } } } = event

    this.previewImageTarget.src = preview
  }

  get uppyFileId () {
    return this.element.dataset.uppyFileId
  }

  get uppyId () {
    return this.element.dataset.uppyId
  }

  get uppy () {
    return window.Uppy.Core.get(this.uppyId)
  }

  get uppyFile () {
    return FileStore.get(this.uppyId)?.getFile(this.uppyFileId)
  }
}
