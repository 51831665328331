// Provides a global interface for tracking analytics events from the native app.
// Requires that the Analytics Bridge controller is attached to the document element.

class NativeAnalytics {
  track (event, properties) {
    if (!this.controller) { return }

    this.controller.track(event, properties)
  }

  get controller () {
    return this.controllerElement && this.controllerElement['bridge--analytics']
  }

  get controllerElement () {
    return document.querySelector('[data-controller~="bridge--analytics"]')
  }
}

window.nativeAnalytics = new NativeAnalytics()
