class FileStore {
  constructor () {
    this.cache = {}
  }

  get (key) {
    return this.cache[key]
  }

  set (key, value) {
    return this.cache[key] = value
  }

  remove (key) {
    return this.cache[key] = null
  }

  reset (values) {
    this.cache = values
  }
}

export default new FileStore()
