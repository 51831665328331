// This adds 'data-turbo-permanent' to forms when they are focused, and removes it when they are blurred.
// This will ensure that the form is not morphed (loosing it's values) when a replace morph is performed.

const markAsPermanent = (element) => {
  const isPermanent = element.getAttribute("data-turbo-permanent")

  if (isPermanent) {
    element.setAttribute("data-turbo-permanent-was", !!isPermanent);
  } else {
    element.setAttribute("data-turbo-permanent", true);
  }
}

const unmarkAsPermanent = (element) => {
  if (!element.hasAttribute("data-turbo-permanent-was")) {
    element.removeAttribute("data-turbo-permanent")
  }
}

// This marks any inputs as permanent when they are focused or changed.
const inputEvents = ["change", "keydown"]
inputEvents.forEach((eventName) => {
  addEventListener(eventName, (event) => {
    const { target } = event
    if (!target.matches("input, textarea, select")) return

    markAsPermanent(target)
  })
})

// This marks any forms as permanent when their inputs are focused and unmark again
// when the inputs are blurred.
addEventListener("focusin", (event) => {
  const { target } = event
  if (!target.matches("input, textarea, select")) return

  const form = target.closest("form")
  if (!form) return

  markAsPermanent(form)
})

addEventListener("focusout", (event) => {
  const { target } = event
  if (!target.matches("input, textarea, select")) return

  const form = target.closest("form")
  if (!form) return

  unmarkAsPermanent(form)
})

// When a form is reset, we'll mark it's inputs as non-permanent:
addEventListener("reset", (event) => {
  const { target } = event

  target.querySelectorAll("input[data-turbo-permanent], textarea[data-turbo-permanent], select[data-turbo-permanent]").forEach((input) => {
    unmarkAsPermanent(input)
  })
})
