import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  connect() {
    super.connect()

    this.element.addEventListener("turbo:submit-start", this.busy)
    this.element.addEventListener("turbo:submit-end", this.end)
    this.element.addEventListener("turbo:submit-success", this.success)
  }

  disconnect() {
    super.disconnect()

    this.element.removeEventListener("turbo:submit-start", this.busy)
    this.element.removeEventListener("turbo:submit-end", this.end)
    this.element.removeEventListener("turbo:submit-success", this.success)
  }

  busy = () => {
    this.element.ariaBusy = true
  }

  end = () => {
    this.element.ariaBusy = false
  }

  success = () => {
    this.element.classList.add("complete")
    this.buttonElement.disabled = true
  }

  get buttonElement () {
    return this.element.querySelector("button")
  }
}
