import { v4 as uuidv4 } from "uuid"

// Google Tag Manager will store the *last* given value as it's data attribute accessible in the
// dataLayer variables. This small wrapper ensures that we reset the `uuid` each time we push and
// event to the dataLayer, making sure that each event will have a unique id.

class DataLayerEvents {
  constructor (queue) {
    queue.map(this.push)
  }

  push (event) {
    const uuid = event.uuid || uuidv4() // If no uuid is provided in event props, generate a new one.
    const payload = { ...event, uuid: uuid }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(payload)
  }
}

window.dataLayerEvents = new DataLayerEvents(window.dataLayerEvents || [])

export const pushEvent = (event) => {
  window.dataLayerEvents.push(event)
}
