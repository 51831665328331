import ApplicationController from "./application_controller"

import Uppy from "@uppy/core"
import XHRUpload from "@uppy/xhr-upload"

import ImageEditor from "./uploader/image_editor"
import ThumbnailGenerator from "./uploader/thumbnail_generator"
import Preview from "./uploader/preview"
import GlobalCache from "./uploader/global_cache"

function getMetaValue (name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)

  if (element) {
    return element.getAttribute("content")
  }
}

function htmlToElements (html) {
  const template = document.createElement("template")
  template.innerHTML = html

  return template.content.children
}

export default class extends ApplicationController {
  static targets = ["fileInput", "form", "previewContainer", "previewTemplate"]
  static classes = ["supported"]
  static values = {
    imageEditorConfig: {
      type: Object,
      default: { enabled: false }
    },
  }

  connect () {
    super.connect()

    this.uppy = new Uppy({
      autoProceed: true
    })

    this.uppy.use(XHRUpload, {
      endpoint: this.url,
      headers: this.headers,
      method: this.formMethod,
      fieldName: this.fieldName,
      limit: 1, // Only allow uploading one file at a time to prevent SQL deadlocks caused by our callbacks.

      // Uppy normally parses and emit a JSON response when upload is completed.
      // However, we just want access to the full response in order to determine if we'll
      // handle it as Turbo Stream or not.
      getResponseData: (responseText, response) => response,
    })

    this.uppy.use(ThumbnailGenerator)
    this.uppy.use(ImageEditor, this.imageEditorConfigValue)
    this.uppy.use(GlobalCache)

    if (this.previewsEnabled) {
      this.uppy.use(Preview, {
        previewTemplateTarget: this.previewTemplateTarget,
        previewContainerTarget: this.previewContainerTarget,
      })
    }

    this.uppy.on("upload-success", this.didFinishUploadingFile)
    this.uppy.on("upload-error", this.didErrorWhileUploadingFile)
    this.uppy.on("file-added", this.didAddFile)
    this.uppy.on("progress", this.updateAriaAttributes)
    this.uppy.on("complete", this.didFinishUploadingAllFiles)
    this.uppy.on("upload", this.didStartUpload)

    this.fileInputTarget.addEventListener("change", this.didSelectFiles)

    this.element.classList.add(this.supportedClass)
  }

  get supportedClass () {
    return this.element.dataset.supportedClass || "supported"
  }

  disconnect () {
    super.disconnect()

    this.uppy.close()

    if (this.hasFileInputTarget) {
      this.fileInputTarget.removeEventListener("change", this.didSelectFiles)
    }
  }

  didSelectFiles = (event) => {
    const files = Array.from(event.target.files)

    files.forEach(async (file) => {
      try {
        // Fix for ensuring Chromium based browsers can read the file on Android.
        //
        // Calling #size on the File (which Uppy does), before actually loading the Blob
        // into memory, will throw an ERR_UPLOAD_FILE_CHANGED error on Android.
        //
        // We can workaround this by enforcing a read of the file into memory before
        // passing it to Uppy. Instead of passing the `File` pointer to Uppy, we read it, and pass
        // the Blob instead.
        //
        // See: https://issues.chromium.org/issues/40123366
        const buffer = await file.arrayBuffer();
        const blob = new Blob([buffer], { type: file.type });

        this.uppy.addFile({
          source: "File Input",
          name: file.name,
          type: file.type,
          data: blob,
        })
      } catch (error) {
        if (error.isRestriction) {
          // handle restrictions
          console.log("Restriction error:", error)
        } else {
          // handle other errors
          console.error(error)
        }
      }
    })
  }

  showFilePicker = (event) => {
    this.fileInputTarget.click()

    event.preventDefault()
    return false
  }

  // Private

  didAddFile = (file) => {
    // Include an `uppy_id` and `uppy_file_id` as the metadata when submitting the file.
    // This allows our server-side to target the preview element after uploading.
    this.uppy.setFileMeta(file.id, {
      uppy_file_id: file.id,
      uppy_id: this.uppyId
    })

    const event = new CustomEvent("uploader:file-added", { detail: { file }, bubbles: true })
    this.element.dispatchEvent(event)
  }

  didErrorWhileUploadingFile = (file, error, response) => {
    if (error.isNetworkError && !file.retries) {
      console.warn("Network error uploading file, retrying...", error)

      file.retries ||= 0
      file.retries++

      this.uppy.retryUpload(file.id)
    }
  }

  didFinishUploadingFile = (file, response) => {
    const xhrRequest = response.body

    // We ensure that a response will be evaluated as a Turbo Stream response
    // if the header indicates that we want to do so.
    if (this.isTurboStream(xhrRequest)) {
      Array.from(htmlToElements(xhrRequest.response)).forEach(streamElement => {
        this.element.appendChild(streamElement)
      })
    }
  }

  didFinishUploadingAllFiles = (result) => {
    const event = new CustomEvent("uploader:complete", { detail: { result }, bubbles: true })
    this.element.dispatchEvent(event)
  }

  didStartUpload = () => {
    const event = new CustomEvent("uploader:upload", { bubbles: true })
    this.element.setAttribute("aria-busy", true)

    this.element.dispatchEvent(event)
  }

  updateAriaAttributes = (progress) => {
    this.element.setAttribute("aria-busy", progress !== 100)
  }

  isTurboStream = (xhrRequest) => {
    return xhrRequest.getResponseHeader("content-type").match(/text\/vnd\.turbo-stream\.html/)
  }

  reset = () => {
    if (this.hasPreviewContainerTarget) {
      this.previewContainerTarget.innerHTML = ""
    }
  }

  get url () {
    return this.formTarget.action
  }

  get fieldName () {
    return this.fileInputTarget.name
  }

  get formMethod () {
    return this.formTarget.method || "post"
  }

  get previewsEnabled () {
    return this.hasPreviewContainerTarget && this.hasPreviewTemplateTarget
  }

  get headers () {
    return {
      "X-CSRF-Token": getMetaValue("csrf-token"),
      "Accept": "text/vnd.turbo-stream.html"
    }
  }

  get uppyId () {
    return this.uppy.opts.id
  }
}
