const isPreloading = (event) => {
  const { target, detail: { fetchOptions } } = event

  return target.dataset.turboPreload && fetchOptions.headers['X-Sec-Purpose'] == "prefetch"
}

document.addEventListener("turbo:before-fetch-request", (event) => {
  // Turbo 8 doesn't differentiate between preloading and prefetching.

  // Prefetching happens when doing instaclick-style preloads, while
  // preloads happen when doing [data-turbo-preload].

  // We want to differentiate between the two, so we can set different
  // headers (`preload`) when we're doing a preload, so our backend
  // can differentiate between the two.

  if (isPreloading(event)) {
    event.detail.fetchOptions.headers['X-Sec-Purpose'] = "preload"
  }
})
